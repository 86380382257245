// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyAeXIWDn3hxdrM3ItF3IHQ6JztAq3kgCyc",
    authDomain: "ecoecoles-site-preprod.firebaseapp.com",
    projectId: "ecoecoles-site-preprod",
    storageBucket: "ecoecoles-site-preprod.appspot.com",
    messagingSenderId: "1049261808675",
    appId: "1:1049261808675:web:1d6936444c4294c942a596"
  },

  firebaseBDEE: {
    apiKey: "AIzaSyDO0PuTz7kO7VW0qtJh-UhHaqHl1h4h8oM",
    authDomain: "eepreprod.firebaseapp.com",
    databaseURL: "https://eepreprod.firebaseio.com",
    projectId: "eepreprod",
    storageBucket: "eepreprod.appspot.com",
    messagingSenderId: "838196017488",
    appId: "1:838196017488:web:67225105a1b37792ec9455",
    measurementId: "G-R5QDV4FXZV"
  },
  //FirebaseAPI: 'http://localhost:5001/ecoecoles-b5b28/us-central1/api',
  // FirebaseAPI: 'https://us-central1-ecoecoles-b5b28.cloudfunctions.net/api',
  FirebaseAPI: '',
  FirebaseAPIBDEE: "https://us-central1-eepreprod.cloudfunctions.net/eeapp/",
  // FirebaseAPIBDEE: "http://localhost:5000/eepreprod/us-central1/eeapp/",


  Upload: {
    apiUrl: 'https://files.ecoecoles.ma/website/upload.php',
    secret: 'ShVmYq3t6v9y$B&E)H@McQfTjWnZr4u7x!z%C*F-JaNdRgUkXp2s5v8y/B?D(G+K'
  },
  GoogleAnalyticsID: 'UA-11773637-11'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
