import { TranslateLoader } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirestoreTranslationsLoaderService implements TranslateLoader {

  constructor(@Inject('firebaseProject1') private db: AngularFirestore) { }

  /**
   * Get global translations: menu, footer, ... (used by ngx-translate module)
   *
   * @param lang
   * @param prefix
   */
  getTranslation(lang: string, prefix: string = 'translations'): Observable<any> {
    return this.db.doc(`${prefix}/${lang}`).valueChanges();
  }

}
