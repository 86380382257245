import { Component, OnInit, HostListener, AfterViewInit, AfterContentInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent implements OnInit, AfterViewInit ,AfterContentInit{
  ngAfterContentInit(): void {
    // var percentageOn1 = window.innerWidth / 1920 ;
    // let a : HTMLElement = (document.getElementsByClassName('appWraper')[0] as HTMLElement);
    // console.log(a);
    // a.style.transform = "scale("+percentageOn1+")"
    // document.querySelector('.appWraper').style
  }
  ngAfterViewInit(): void {
  
  }
  onResize(event){
    let a = (document.getElementsByTagName('html') as HTMLCollectionOf<HTMLHtmlElement>)[0];
    if(window.innerWidth  > 769){
      var percentageOn1 = window.innerWidth / 1920 ;
      a.style.zoom=""+(percentageOn1*100)+"%";
      document.body.clientHeight
      
    }
    else{
      a.style.zoom="100%"
    }
  }
  constructor(private translate: TranslateService, private meta: Meta , private router : Router) {
    this.translate.setDefaultLang('fr');
      this.router.events.subscribe(t=>{
            //console.log(t['url']?.indexOf("admin"));
             let a = (document.getElementsByTagName('html') as HTMLCollectionOf<HTMLHtmlElement>)[0];
             if(t['url']?.indexOf("admin")==1){
               a.style.zoom="100%"
            }
           
          });
  }

  ngOnInit() {
    // Add meta tags
    this.meta.addTags([
      {name: 'keywords', content: 'EcoEcole'},
      {name: 'description', content: 'Bienvenue sur EcoEcole'},
      {name: 'robots', content: 'index, follow'}
    ]);
   
    let a = (document.getElementsByTagName('html') as HTMLCollectionOf<HTMLHtmlElement>)[0];
    let b = (document.getElementsByTagName('body') as HTMLCollectionOf<HTMLBodyElement>)[0];
    // this.router.url.indexOf('admin')>-1
    if(window.innerWidth  > 769 ){
      var percentageOn1 = window.innerWidth / 1920;
      a.style.zoom=""+(percentageOn1*100)+"%";
      window.addEventListener('scroll', this.scrollEvent, true);
      a.style.height = a.style.height + 400;
      //console.log(a.style.height)
      //console.log(window.innerWidth,percentageOn1)
    }
    else{
      a.style.zoom="100%"
    }
    
  }

  scrollEvent = (event: any): void => {

   // console.log(event.srcElement.scrollingElement);
   //console.log(window.scrollY, window.innerHeight ,document.body.scrollHeight)
    // if ((window.innerHeight) >= document.body.offsetHeight) {
    //   //alert("end")
    //   console.log("end")
    // }
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
     // alert("you're at the bottom of the page");
     }
   // window.scrollTo(0,event.srcElement.scrollingElement.scrollTop+100);  
  }
}
