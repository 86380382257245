import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { FirestoreTranslationsLoaderService } from 'src/app/shared/services/firestore-translations-loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  AngularFirestoreProject1,
  AngularFirestoreProject2,
  AngularFireAuthProject1,
  AngularFireAuthProject2
} from './shared/services/firebase.factory';

// Register locales for date pipe
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeAr from '@angular/common/locales/ar';
import { HttpClientModule } from '@angular/common/http';
registerLocaleData(localeFr);
registerLocaleData(localeAr);

export function FirestoreTranslationsLoaderFactory(db: AngularFirestore) {
  return new FirestoreTranslationsLoaderService(db);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase), // npm install @angular/fire firebase --save
    AngularFirestoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FirestoreTranslationsLoaderFactory,
        deps: [AngularFirestore]
      },
      isolate: false
    }), // npm install @ngx-translate/core --save
  ],
  providers: [
    AngularFirestore,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },

    {
    provide: 'env',
    useValue: environment
  },
  {
    provide: 'firebaseProject1',
    deps: [PLATFORM_ID, NgZone],
    useFactory: AngularFirestoreProject1
  },
  {
    provide: 'firebaseProject2',
    deps: [PLATFORM_ID, NgZone],
    useFactory: AngularFirestoreProject2
  },
  {
    provide: 'firebaseAuthProject1',
    deps: [PLATFORM_ID, NgZone],
    useFactory: AngularFireAuthProject1
  },
  {
    provide: 'firebaseAuthProject2',
    deps: [PLATFORM_ID, NgZone],
    useFactory: AngularFireAuthProject2
  }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
