import { NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';



export function AngularFirestoreProject1(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebase, 'firebase', false, null, platformId, zone, null, null, null);
}

export function AngularFirestoreProject2(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebaseBDEE, 'firebaseBDEE', false, null, platformId, zone, null, null, null);
}

export function AngularFireAuthProject1(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(environment.firebase, 'firebase-auth', platformId, zone,null, null, null, null, null, null);
}


export function AngularFireAuthProject2(platformId: Object, zone: NgZone) {
  return new AngularFireAuth(environment.firebaseBDEE, 'firebaseBDEE-auth', platformId, zone,null, null, null, null, null, null);
}
